/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Provider } from '@adobe/react-spectrum';
import { theme as expressTheme } from '@react-spectrum/theme-express';
import App from 'App';
import 'index.css';
import { HitProvider } from 'providers/HitProvider';
import { MessageDialogProvider } from 'providers/MessageDialogProvider';
import { RepositoryProvider } from 'providers/RepositoriesProvider';
import { ThemeProvider, useTheme } from 'providers/ThemeProvider';
import { UserProvider } from 'providers/UserProvider';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

const rootEl = document.getElementsByTagName('main')[0] || document.getElementById('root');
const root = ReactDOM.createRoot(rootEl);

function MyApp() {
  const { theme } = useTheme();
  return (
    <Provider theme={expressTheme} colorScheme={theme} height="100%">
      <MessageDialogProvider>
        <UserProvider>
          <HitProvider>
            <RepositoryProvider>
              <App />
              <div id="portal-root"></div>
            </RepositoryProvider>
          </HitProvider>
        </UserProvider>
      </MessageDialogProvider>
    </Provider>
  );
}

root.render(
  <ThemeProvider>
    <MyApp />
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);

// add the favicon delayed
const link = document.createElement('link');
link.rel = 'icon';
link.href = '/icons/favicon.ico';
document.getElementsByTagName('head')[0].appendChild(link);
