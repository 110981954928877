/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

import { useState, createContext, useContext } from 'react';
import { DialogContainer } from '@adobe/react-spectrum';

const DialogContext = createContext(null);

/**
 * Simple mechanism for showing small dialogs, like alerts, confirmations, etc.
 * for example using the react-spectrum AlertDialog. Ensures that only one
 * message dialog is shown at a time.
 *
 * Include this provider once at the top of your component tree, and use the useDialog hook
 * in components that need to display a message dialog on demand.
 */
export function MessageDialogProvider({ children }) {
  const [dialog, setDialog] = useState(null);

  return (
    <DialogContext.Provider value={{ setDialog }} >
      { children }
      <DialogContainer>
        { dialog }
      </DialogContainer>
    </DialogContext.Provider>
  );
}

/**
 * Hook to show dialogs. The returned object has a show() function to
 * display any react-spectrum Dialog, and other convenience functions.
 */
export function useDialog() {
  const { setDialog } = useContext(DialogContext);

  return {
    /* Show any custom react spectrum <Dialog> by passing the dialog. Typically an <AlertDialog>. */
    show(dialog) {
      setDialog(dialog);
    },

    /* Closes the dialog */
    close() {
      // remove the dialog = closing
      setDialog();
    },
  };
}
