/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { getSpaceHost } from 'components/utils';

/**
 * @typedef {Object} Source
 * @property {string} sourceId
 * @property {string} sourceType
 * @property {string} [name]
 * @property {string} [description]
 */

/**
 * @typedef {Object} SourcePage
 * @typedef {Array<Source>} items
 * @typedef {number} count
 * @typedef {string} [cursor]
 */

const API_PREFIX = '/api/content-lake-extractor/v1';

/**
 * @param {string} endpoint the endpoint (including query parameters) to retrieve
 * @param {RequestInit} [init] the request init to merge with the default
 * @returns {Promise<Response>}
 */
const doFetch = (endpoint, init) => window.user.fetch(`https://${getSpaceHost()}${API_PREFIX}${endpoint}`, {
  ...init,
  cache: 'no-cache',
  headers: {
    ...init?.headers,
    'Content-Type': 'application/json',
  },
});

/**
 * Handles the specified response
 * @param {Response} response the response to handle
 * @returns {Promise<void>} a promise which resolves if the response is 'ok'
 */
const handleResponse = async (response) => {
  if (!response.ok) {
    const body = await response.text();
    throw new Error(
      `Failed to call ${response.url}, received invalid response. Status: ${response.status
      } X-Error: ${response.headers.get('x-error')} Body: ${body}`,
    );
  }
};

/**
 * Handles the specified response
 * @param {Response} response the response to handle
 * @returns {Promise<Object>} the JSON payload
 */
const handleJsonResponse = async (response) => {
  await handleResponse(response);
  return response.json();
};

/**
 * @param {string} sourceType
 * @param {string} sourceId
 * @param {Object} settings
 * @returns {Promise<Source>}
 */
export const createSource = async (sourceType) => {
  const res = await doFetch(
    `/${encodeURIComponent(sourceType)}`,
    { method: 'POST' },
  );
  return handleJsonResponse(res);
};

/**
 * @param {string} sourceType
 * @param {string} sourceId
 * @returns {Promise<void>}
 */
export const deleteSource = async (sourceType, sourceId) => {
  const res = await doFetch(
    `/${encodeURIComponent(sourceType)}/${encodeURIComponent(sourceId)}`,
    { method: 'DELETE' },
  );
  await handleResponse(res);
};

/**
 * @returns {Promise<string>}
 */
export const getAuthenticationUrl = async (sourceType, sourceId) => {
  const res = await doFetch(
    `/${encodeURIComponent(sourceType)}/${encodeURIComponent(
      sourceId,
    )}/authenticate`,
  );
  const json = await handleJsonResponse(res);
  return json.authenticationUrl;
};

/**
 * @param {string} [cursor]
 * @returns {<Promise<SourcePage>}
 */
export const getSources = async (cursor) => {
  const params = new URLSearchParams();
  if (cursor) {
    params.append('cursor', cursor);
  }
  const res = await doFetch(`/?${params.toString()}`);
  return handleJsonResponse(res);
};

/**
 * @param {string} sourceType
 * @param {string} sourceId
 * @returns {Promise<SourcePage>}
 */
export const getSource = async (sourceType, sourceId) => {
  const res = await doFetch(
    `/${encodeURIComponent(sourceType)}/${encodeURIComponent(sourceId)}`,
  );
  return handleJsonResponse(res);
};

/**
 * @param {string} sourceType
 * @param {string} sourceId
 * @param {string} secret
 * @returns {<Promise<void>}
 */
export const updateSecret = async (sourceType, sourceId, secret) => {
  const res = doFetch(
    `/${encodeURIComponent(sourceType)}/${encodeURIComponent(
      sourceId,
    )}/secret`,
    { method: 'PUT', body: JSON.stringify(secret) },
  );
  await handleResponse(res);
};

/**
 * @param {Source} source
 * @returns {Promise<SourcePage>}
 */
export const updateSource = async (source) => {
  const res = await doFetch(
    `/${encodeURIComponent(source.sourceType)}/${encodeURIComponent(
      source.sourceId,
    )}`,
    { method: 'PUT', body: JSON.stringify(source) },
  );
  return handleJsonResponse(res);
};
