/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Checkbox, CheckboxGroup, Item, TagGroup, View } from '@adobe/react-spectrum';
import { getSourceDisplayName } from 'components/utils';
import { ACTIONS, useHitDispatch } from 'providers/HitProvider';

export default function TagsFilter({ options, values }) {
  const hitDispatch = useHitDispatch();

  const tagList = values?.map((value) => value.replace('tags:', ''));
  const tagsForTagGroup = tagList?.map((item) => ({ id: item, name: item }));

  const handleSelectionsChange = (selections) => {
    hitDispatch(ACTIONS.setFilters('tags', selections));
  };

  const onRemove = (removeSet) => {
    const removeTag = removeSet.values().next().value;
    const newSelection = tagList.filter((item) => item !== removeTag);
    handleSelectionsChange(newSelection);
  };

  return (
    <>
      {tagsForTagGroup.length > 0 && (
        <View
          padding="size-50"
          borderWidth="thin"
          borderColor="dark"
          borderRadius="small"
          backgroundColor="gray-50"
        >
          <TagGroup items={tagsForTagGroup} onRemove={onRemove} aria-label="TagGroup for dynamic tags">
            {(item) => <Item>{item.name}</Item>}
          </TagGroup>
        </View>
      )}
      <CheckboxGroup
        onChange={handleSelectionsChange}
        isEmphasized={true}
        key="tags"
        value={tagList}
        data-instance-id="search-filter-group"
        aria-label="Filter assets by tags"
      >
        {Object.entries(options).map(([name, count]) => (
          <Checkbox value={name} key={name}>
            {`${getSourceDisplayName(name)} (${count})`}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </>
  );
}
