/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { FACETS, countFacets, doPage, doSearch } from 'connectors/SearchConnector';

const defaultState = {
  hits: [],
  facets: {},
  filters: {},
  query: '',
  selectedAssetIdentity: null,
  showFilter: true,
  loadMore: () => {},
};

Object.keys(FACETS).forEach((facet) => {
  defaultState.filters[facet] = FACETS[facet].defaultValue;
});

const HitContext = createContext(defaultState);

const HitDispatchContext = createContext(null);

export function useHit() {
  return useContext(HitContext);
}

export function useHitDispatch() {
  return useContext(HitDispatchContext);
}

const REDUCERS = {
  setQuery: (state, action) => ({ ...state, query: action.query }),
  setFilters: (state, action) => {
    const { filters } = state;
    const { facet, filters: actionFilters } = action;

    return {
      ...state,
      filters: {
        ...filters,
        [facet]: actionFilters.map((id) => `${facet}:${id}`),
      },
    };
  },
  resetFilters: (state) => ({
    ...state,
    filters: defaultState.filters,
  }),
  toggleFilterPanel: (state) => ({ ...state, showFilter: !state.showFilter }),
  updateFilter: (state, action) => {
    const { filters } = state;
    const { facetType, facetId, isSelected } = action;
    const facet = `${facetType}:${facetId}`;

    return {
      ...state,
      selectedAssetIdentity: null,
      filters: {
        ...filters,
        [facetType]: isSelected
          ? [...filters[facetType], facet]
          : filters[facetType].filter((f) => f !== facet),
      },
    };
  },
  appendResponse: (state, action) => ({
    ...state,
    hits: [...state.hits, ...action.hits],
  }),
  clearSelectedAssetIdentity: (state) => ({
    ...state,
    selectedAssetIdentity: null,
  }),
  toggleSelectedAssetIdentity: (state, action) => ({
    ...state,
    selectedAssetIdentity:
      state.selectedAssetIdentity === action.assetIndentity ? null : action.assetIndentity,
  }),
  selectedAssetIdentityNext: (state) => {
    const { selectedAssetIdentity, hits } = state;
    const currentIndex = hits.findIndex((hit) => hit.assetIdentity === selectedAssetIdentity);
    const nextIndex = currentIndex + 1;
    const nextAsset = hits[nextIndex];
    return {
      ...state,
      selectedAssetIdentity: nextAsset ? nextAsset.assetIdentity : null,
    };
  },
  selectedAssetIdentityPrevious: (state) => {
    const { selectedAssetIdentity, hits } = state;
    const currentIndex = hits.findIndex((hit) => hit.assetIdentity === selectedAssetIdentity);
    const previousIndex = currentIndex - 1;
    const previousAsset = hits[previousIndex];
    return {
      ...state,
      selectedAssetIdentity: previousAsset ? previousAsset.assetIdentity : null,
    };
  },
};

const ACTION_NAMES = {
  setQuery: 'setQuery',
  setResponse: 'setResponse',
  setFilters: 'setFilters',
  updateFilter: 'updateFilter',
  resetFilters: 'resetFilters',
  appendResponse: 'appendResponse',
  toggleFilterPanel: 'toggleFilterPanel',
  clearSelectedAssetIdentity: 'clearSelectedAssetIdentity',
  toggleSelectedAssetIdentity: 'toggleSelectedAssetIdentity',
  selectedAssetIdentityNext: 'selectedAssetIdentityNext',
  selectedAssetIdentityPrevious: 'selectedAssetIdentityPrevious',
};

function hitReducer(state, action) {
  switch (action.type) {
    case ACTION_NAMES.setResponse: {
      return { ...state, hits: action.hits, facets: { assetStatus: FACETS.assetStatus, ...action.facets } };
    }
    case ACTION_NAMES.setQuery:
      return REDUCERS.setQuery(state, action);
    case ACTION_NAMES.setFilters:
      return REDUCERS.setFilters(state, action);
    case ACTION_NAMES.updateFilter:
      return REDUCERS.updateFilter(state, action);
    case ACTION_NAMES.resetFilters:
      return REDUCERS.resetFilters(state);
    case ACTION_NAMES.toggleFilterPanel:
      return REDUCERS.toggleFilterPanel(state);
    case ACTION_NAMES.appendResponse:
      return REDUCERS.appendResponse(state, action);
    case ACTION_NAMES.clearSelectedAssetIdentity:
      return REDUCERS.clearSelectedAssetIdentity(state);
    case ACTION_NAMES.toggleSelectedAssetIdentity:
      return REDUCERS.toggleSelectedAssetIdentity(state, action);
    case ACTION_NAMES.selectedAssetIdentityNext:
      return REDUCERS.selectedAssetIdentityNext(state);
    case ACTION_NAMES.selectedAssetIdentityPrevious:
      return REDUCERS.selectedAssetIdentityPrevious(state);
    default:
      throw Error(`Unknown action: ${action.type}`);
  }
}

export const ACTIONS = {
  setQuery: (query) => ({
    type: ACTION_NAMES.setQuery,
    query,
  }),
  clearQuery: () => ({
    type: ACTION_NAMES.setQuery,
    query: '',
  }),
  setFilters: (facet, filters) => ({
    type: ACTION_NAMES.setFilters,
    filters,
    facet,
  }),
  updateFilter: (facetType, facetId, isSelected) => ({
    type: ACTION_NAMES.updateFilter,
    facetType,
    facetId,
    isSelected,
  }),
  resetFilters: () => ({
    type: ACTION_NAMES.resetFilters,
  }),
  setResponse: (hits, facets) => ({
    type: ACTION_NAMES.setResponse,
    hits,
    facets,
  }),
  appendResponse: (hits) => ({
    type: ACTION_NAMES.appendResponse,
    hits,
  }),
  toggleFilterPanel: () => ({
    type: ACTION_NAMES.toggleFilterPanel,
  }),
  toggleSelectedAssetIdentity: (assetIndentity) => ({
    type: ACTION_NAMES.toggleSelectedAssetIdentity,
    assetIndentity,
  }),
  clearSelectedAssetIdentity: () => ({
    type: ACTION_NAMES.clearSelectedAssetIdentity,
  }),
  selectedAssetIdentityNext: () => ({
    type: ACTION_NAMES.selectedAssetIdentityNext,
  }),
  selectedAssetIdentityPrevious: () => ({
    type: ACTION_NAMES.selectedAssetIdentityPrevious,
  }),
};

/**
 *
 * @param {object} state
 * @param {Function} dispatch
 * @returns {{loadMore: Function}}
 */
const useSearch = (state, dispatch) => {
  const [nextSearch, setNextSearch] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const loadMore = useCallback(async () => {
    if (nextSearch.hasMore && !isLoading) {
      setIsLoading(true);
      const filters = Object.values(state.filters).flat();
      const result = await doPage(
        nextSearch.page + 1,
        filters,
        state.query,
      );
      if (result) {
        const { hits } = result;
        dispatch(ACTIONS.appendResponse(hits));
        setNextSearch(result);
      }
      setIsLoading(false);
    }
  }, [isLoading, nextSearch, dispatch]);

  useEffect(() => {
    const fetch = async () => {
      const filters = Object.values(state.filters).flat();
      const searchResult = await doSearch(filters, state.query);
      const facetsCount = await countFacets(state.query, filters);
      if (searchResult && facetsCount) {
        setIsLoading(false);
        dispatch(ACTIONS.setResponse(searchResult.hits, facetsCount));
        setNextSearch(searchResult);
        setIsLoading(false);
      }
    };

    if (dispatch) {
      fetch();
    }
  }, [state.query, state.filters, dispatch]);

  return { loadMore, isLoading, hasMore: nextSearch.hasMore };
};

const getInitialStateFromURL = () => {
  const { searchParams } = new URL(window.location.href);
  const filters = {};
  Object.keys(FACETS).forEach((facet) => {
    filters[facet] = FACETS[facet].defaultValue;
  });
  Object.keys(FACETS).forEach((facet) => {
    if (searchParams.getAll(facet).length !== 0) {
      filters[facet] = searchParams.getAll(facet).map((item) => `${facet}:${item}`);
    }
  });

  return {
    ...defaultState,
    query: searchParams.get('query') || defaultState.query,
    selectedAssetIdentity: searchParams.get('detail') || defaultState.selectedAssetIdentity,
    filters,
    showFilter: true,
  };
};

const setStateToURL = (query, selectedAssetIdentity, filters) => {
  // get url without search params
  const currentURL = new URL(window.location.href.split('?')[0]);
  const { searchParams } = currentURL;

  if (query) {
    searchParams.set('query', query);
  }

  if (selectedAssetIdentity) {
    searchParams.set('detail', selectedAssetIdentity);
  }

  Object.keys(FACETS).forEach((facet) => {
    if (filters[facet]) {
      filters[facet].forEach((value) => searchParams.append(facet, value.replace(`${facet}:`, '')));
    }
  });
  // eslint-disable-next-line no-restricted-globals
  history.replaceState({}, '', currentURL.toString());
};

function HitProvider({ children }) {
  const [state, dispatch] = useReducer(hitReducer, getInitialStateFromURL());

  const { loadMore, isLoading, hasMore } = useSearch(state, dispatch);

  useEffect(() => {
    setStateToURL(state.query, state.selectedAssetIdentity, state.filters);
  }, [state.query, state.selectedAssetIdentity, state.filters]);

  return (
    <HitContext.Provider value={{ ...state, loadMore, isLoading, hasMore }}>
      <HitDispatchContext.Provider value={dispatch}>
        {children}
      </HitDispatchContext.Provider>
    </HitContext.Provider>
  );
}

export { HitProvider };
