/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import ColorFilter from 'components/filter/ColorFilter';
import MimeTypeFilter from 'components/filter/MimeTypeFilter';
import ContentCredentialFilter from 'components/filter/ContentCredentialFilter';
import StatusFilter from 'components/filter/StatusFilter';
import TagsFilter from 'components/filter/TagsFilter';
import RepositoryFilter from 'components/filter/RepositoryFilter';
import { getSpaceHost } from 'components/utils';

export const FACETS = {
  sourceTypes: {
    defaultValue: [],
    order: 0,
    render: ({ fullFacets }) => <RepositoryFilter fullFacets={fullFacets} />,
  },
  assetStatus: {
    defaultValue: ['assetStatus:Ready to Use', 'assetStatus:Published'],
    order: 1,
    render: ({ options, values }) => <StatusFilter options={options} values={values} />,
  },
  sourceMimeTypes: {
    defaultValue: [],
    order: 2,
    render: ({ options, values, fullFacets }) => (
      <MimeTypeFilter options={options} values={values} fullFacets={fullFacets} />
    ),
  },
  c2paLevel: {
    defaultValue: [],
    order: 3,
    render: ({ options, values, fullFacets }) => (
      <ContentCredentialFilter options={options} values={values} fullFacets={fullFacets} />
    ),
  },
  tags: {
    defaultValue: [],
    order: 4,
    render: ({ options, values }) => <TagsFilter options={options} values={values} />,
  },
  color: {
    defaultValue: [],
    order: 5,
    render: ({ options, values }) => <ColorFilter options={options} values={values} />,
  },
  sourceIds: {
    defaultValue: [],
    order: -999,
    hidden: true,
  },
};
const facets = Object.keys(FACETS).join(',');
const executeSearch = async (reqBody) => {
  // convert object to query string
  const params = new URLSearchParams();
  for (const key of Object.keys(reqBody)) {
    params.append(key, reqBody[key]);
  }

  // set default hitsPerPage
  if (!params.has('hitsPerPage')) {
    params.set('hitsPerPage', 50);
  }

  const response = await window.user.fetch(`https://${getSpaceHost()}/api/search?${params}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

const translateFilterStr = (filters) => {
  const filterObj = {};
  filters.forEach((item) => {
    const arr = item.split(':');
    if (arr.length === 2) {
      if (!filterObj[arr[0]]) {
        filterObj[arr[0]] = [arr[1]];
      } else {
        filterObj[arr[0]].push(arr[1]);
      }
    }
  });
  return Object.keys(filterObj)
    .map((field) => {
      if (field === 'tags') {
        return filterObj[field].map((item) => `${field}:"${item}"`).join(' AND ');
      } else {
        return filterObj[field].map((item) => `${field}:"${item}"`).join(' OR ');
      }
    })
    .map((filter) => `( ${filter} )`)
    .join(' AND ');
};

/**
 *
 * @param {Array} filters
 * @param {String} query search query
 * @returns
 */
const doSearch = async (filters, query) => {
  const searchRequest = {
    query,
    filters: translateFilterStr(filters),
    facets: Object.keys(FACETS),
  };
  const response = await executeSearch(searchRequest);

  if (response.ok) {
    const data = await response.json();
    const hits = data.hits || [];
    const page = data.page || 0;
    const totalPages = data.nbPages || 0;
    const params = data.params || '';

    return {
      hits,
      facets: data.facets || {},
      page,
      params,
      hasMore: totalPages > page,
    };
  } else {
    const err = new Error('Failed to execute search');
    err.response = response;
    throw err;
  }
};

/**
 * Return counts for different facets while do query
 * @param {String} query search query
 * @returns the facets counts
 */
const countFacets = async (query, filters) => {
  const searchRequest = {
    query,
    facets,
    filters: translateFilterStr(filters),
    maxValuesPerFacet: 8,
    sortFacetValuesBy: 'count',
    hitsPerPage: 0,
    page: 0,
  };
  const response = await executeSearch(searchRequest);

  if (response.ok) {
    const data = await response.json();
    return data.facets || {};
  } else {
    const err = new Error('Failed to execute search');
    err.response = response;
    throw err;
  }
};

/**
 * fetch next page
 * @param {number} page page id to fetch
 * @param {string} params search params
 * @returns
 */
const doPage = async (page, filters, query) => {
  const searchRequest = {
    query,
    filters: translateFilterStr(filters),
    facets,
    page,
  };
  const response = await executeSearch(searchRequest);

  if (response.ok) {
    const data = await response.json();
    const hits = data.hits || [];
    const newPage = data.page || 0;
    const totalPages = data.nbPages || 0;
    const newParams = data.params || '';

    return {
      hits,
      facets: data.facets || {},
      page: newPage,
      params: newParams,
      hasMore: totalPages > newPage,
    };
  } else {
    const err = new Error('Failed to execute search');
    err.response = response;
    throw err;
  }
};

/**
 * sort cluster by file type and image size
 * @param {object[]} assets
 * @returns {object[]}
 */
const clusterSort = (assets) => {
  const images = [];
  const files = [];

  assets.forEach((asset) => {
    if (asset.sourceName.endsWith('.ai') || asset.sourceName.endsWith('.psd')) {
      files.push(asset);
    } else {
      images.push(asset);
    }
  });
  const sortedImages = images.sort((a, b) => b.width * b.height - a.width * a.height);
  return [...files, ...sortedImages];
};

/**
 * Return counts for different facets while do query
 * @param {String} assetIdentity search query
 * @returns {{hits: Array}}
 */
const fetchClusterVariant = async (assetIdentity) => {
  const response = await window.user.fetch(`https://${getSpaceHost()}/api/asset/${assetIdentity}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const data = await response.json();
    const hits = data.hits || [];
    return { hits: clusterSort(hits) };
  } else {
    const err = new Error('Failed to fetch asset details');
    err.response = response;
    throw err;
  }
};

/**
 * Return query string for related assets
 * @param {object} asset
 * @param {number} relatedTerms
 * @returns {string}
 */
const relatedAssetQuery = (asset, relatedTerms = 3) => {
  const { tags, ocrTags, color } = asset;
  // filter ocrTags that only contains letters and length > 2
  let cleanOcrTags = ocrTags.filter((tag) => /^[a-zA-Z]+$/.test(tag)).filter((tag) => tag.length > 2);
  cleanOcrTags = [...new Set(cleanOcrTags)];
  const combineTags = [...tags, ...cleanOcrTags, color];

  return combineTags.slice(0, relatedTerms).join(' ');
};

const fetchRelatedAssetPage = async (query) => {
  const searchRequest = {
    query,
    filters: 'assetStatus:"Ready to Use" OR assetStatus:"Published"',
    facets: 'sourceTypes',
    hitsPerPage: 50,
  };
  const response = await executeSearch(searchRequest);

  if (response.ok) {
    const data = await response.json();
    return data.hits || [];
  } else {
    const err = new Error('Failed to execute search');
    err.response = response;
    throw err;
  }
};

/**
 * Return assets related to the given asset
 * @param {Object} asset
 * @returns {Array}
 */
const fetchRelatedAssets = async (asset) => {
  let relatedAssets = await fetchRelatedAssetPage(relatedAssetQuery(asset));
  if (relatedAssets.length < 10) {
    relatedAssets = await fetchRelatedAssetPage(relatedAssetQuery(asset, 2));
  }
  // filter out the asset itself
  return relatedAssets.filter((item) => item.assetIdentity !== asset.assetIdentity);
};

/**
 * Return suggestions for search query while user typing
 * @param {String} searchStr the prefix of search query to get suggestions
 * @returns {Array} the suggestions list, empty array if nothing found
 */
const querySuggestions = async (searchStr) => {
  const params = new URLSearchParams();
  params.set('query', searchStr);
  const response = await window.user.fetch(`https://${getSpaceHost()}/api/search-suggest?${params}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const data = await response.json();
    return data.hits.map((hit) => hit.query);
  } else {
    return [];
  }
};

export { doSearch, countFacets, doPage, fetchClusterVariant, fetchRelatedAssets, querySuggestions };
