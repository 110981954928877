/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { createContext, useContext, useEffect, useState } from 'react';
import { getSpaceHost, getSpaceId, getSpaceName, isDev } from 'components/utils';
import Authenticator from 'controllers/Authenticator';

import { AlertDialog } from '@adobe/react-spectrum';
import { useDialog } from './MessageDialogProvider';

/**
 * Parses a person's full name (single string) into firstName and lastName (surname).
 *
 * This will take the last word that is separate by a space as the lastName
 * and everything before as firstName. For example, "John William Doe" will be parsed into
 * firstName: "John William" and lastName: "Doe".
 *
 * Yes, this will not always be correct - sometimes people have multiple first names,
 * and other times some might have multiple surnames. But it's a good enough approximation.
 *
 * @param {String} name a full person's name such as "John William Doe"
 * @returns {Object} an object with firstName and lastName properties
 */
function parsePersonName(name) {
  if (!name) return {};

  const nameParts = name.split(' ');
  return {
    firstName: nameParts.slice(0, -1).join(' '),
    lastName: nameParts.slice(-1).join(' '),
  };
}

if (isDev()) {
  document.title = `${getSpaceName()} - Assets`;
}

function useDevAuth() {
  return window.location.hostname === 'localhost';
}

const DEV_AUTH_CONFIG = {
  domain: 'app-9b71tqe2fuco.frontegg.com',
  clientId: '751821ca-4982-4c99-961c-a9c6f38bad52',
};
const PROD_AUTH_CONFIG = {
  domain: 'login.findmy.media',
  clientId: 'e6d80d3a-276b-4a34-be31-8888a23bafa8',
};

window.user = new Authenticator({
  ...(useDevAuth() ? DEV_AUTH_CONFIG : PROD_AUTH_CONFIG),
  appUri: `${window.location.origin}/`,
  autoLogin: true,
  tenantId: useDevAuth() ? '04047447-132b-43d7-bcf9-abdc0eb561a9' : getSpaceId(),
  // TODO: disable Authenticator debug logs once we are confident everything works in all edge cases
  debugLog: true,
});

const UserContext = createContext({ isAuthenticated: false });

export function useUser() {
  return useContext(UserContext);
}

function setGainsightUser(userToken) {
  const emailDomain = userToken.email?.split('@')[1];
  const isAdobe = emailDomain === 'adobe.com';

  let name;
  if (userToken.name.endsWith(' (DEV)')) {
    name = parsePersonName(userToken.name.replace(' (DEV)', ''));
    name.lastName += ' (DEV)';
  } else {
    name = parsePersonName(userToken.name);
  }

  const gainsightUser = {
    id: userToken.sub,
    email: isAdobe ? userToken.email : emailDomain,
    firstName: isAdobe ? name.firstName : undefined,
    lastName: isAdobe ? name.lastName : undefined,
    role: userToken.roles?.[0],
  };

  const gainsightAccount = {
    id: getSpaceId(),
    name: getSpaceHost(),
    website: emailDomain,
  };

  window.aptrinsic?.(
    'identify',
    gainsightUser,
    gainsightAccount,
  );
}

function UserProvider({ children }) {
  const [init, setInit] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(
    window.user.isAuthenticated(),
  );
  const [user, setUser] = useState();

  const dialog = useDialog();

  useEffect(() => {
    if (init) {
      setInit(false);

      window.user.on('authenticated', async (userToken) => {
        // mark dev users clearly (for UI, Gainsight)
        if (useDevAuth()) {
          // eslint-disable-next-line no-param-reassign
          userToken.name += ' (DEV)';
        }

        // this will check authorization for the user and space and set any necessary cookies
        // if this fails we do not abort since this will be called again on the next token refresh
        try {
          const result = await window.user.fetch(`https://${getSpaceHost()}/auth`, {
            method: 'POST',
            credentials: 'include',
          });

          if (!result.ok) {
            console.debug('UserProvider: /auth failure, returned', result.status);
          }

          if (result.status === 403) {
            dialog.show(
              <AlertDialog
                variant="error"
                title="No Access"
                primaryActionLabel="Go to Login"
                onPrimaryAction={ () => { window.user.logout(); } }
              >
                Sorry, user {userToken.name} ({userToken.email}) does not have access to this space.
              </AlertDialog>,
            );
          }
        } catch (e) {
          // network errors can be transient, ignore this, and wait for the next token refresh
          console.debug('UserProvider: /auth request error', e);
        }

        setAuthenticated(true);
        setUser(window.user);
        setGainsightUser(userToken);
      });

      window.user.on('not_authenticated', () => {
        setAuthenticated(false);
        setUser(window.user);
      });

      window.user.init();
    }
  }, [init]);

  return (
    <UserContext.Provider value={{ isAuthenticated, user }}>
      {isAuthenticated && children}
    </UserContext.Provider>
  );
}

export { UserProvider };
