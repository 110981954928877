/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import Feature from '@spectrum-icons/workflow/Feature';
import ColorIcon from 'components/filter/ColorIcon';

const CLSourceIcon = ({ status }) => {
  switch (status) {
    case 'Ready to Use':
      return <Feature color="positive" size="S" />;
    case 'Unknown':
      return <ColorIcon color="grey" />;
    case 'Work in Progress':
      return <ColorIcon color="#9146ED" />;
    case 'In Review':
      return <ColorIcon color="#1473E6" />;
    case 'Embargoed':
      return <ColorIcon color="#E77500" />;
    case 'Published':
      return <ColorIcon color="#5B60EB" />;
    case 'Archived':
      return <ColorIcon color="#8AB403" />;
    default: return <ColorIcon color="grey" />;
  }
};

export default CLSourceIcon;
