/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { CheckboxGroup } from '@adobe/react-spectrum';
import Check from '@spectrum-icons/workflow/CheckmarkCircleOutline';
import { ACTIONS, useHitDispatch } from 'providers/HitProvider';

export default function ColorFilter({ options, values }) {
  const hitDispatch = useHitDispatch();
  const computedValues = values?.map((value) => value.replace('color:', ''));

  const onSelect = (color) => {
    let selected;
    if (computedValues.includes(color)) {
      selected = computedValues.filter((item) => item !== color);
    } else {
      selected = [...computedValues, color];
    }
    hitDispatch(ACTIONS.setFilters('color', selected));
  };

  return (
    <CheckboxGroup isEmphasized={true} aria-label="Filter assets by color">
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '208px',
          borderRadius: '2px',
          overflow: 'hidden',
        }}
      >
        {Object.entries(options).map(([name]) => (
          <div
            key={name}
            style={{
              backgroundColor: name,
              width: '26px',
              height: '26px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            data-instance-id="search-filter-color"
            onClick={() => onSelect(name)}
          >
            {computedValues && computedValues.includes(name) && (
              <Check color="notice" width="20px" />
            )}
          </div>
        ))}
      </div>
    </CheckboxGroup>
  );
}
