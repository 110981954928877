/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Grid, minmax, View } from '@adobe/react-spectrum';
import { ToastContainer } from '@react-spectrum/toast';
import React, { Suspense } from 'react';
import { useHit } from './providers/HitProvider.js';

const MainTopNav = React.lazy(() => import('./components/header/MainTopNav.js'));
const SearchResultsPanel = React.lazy(() => import('./components/results/search-results/SearchResultsPanel.js'));
const FiltersSidePanel = React.lazy(() => import('./components/filter/FiltersSidePanel.js'));
const AssetPreviewDialogContainer = React.lazy(() => import('./components/details/AssetDetailsDialogContainer.js'));

function App() {
  const { showFilter } = useHit();

  const defaultContentColumnRow = 'content / content';
  const contentStart = showFilter
    ? defaultContentColumnRow
    : 'sidebar / sidebar';
  const contentEnd = defaultContentColumnRow;

  return (
    <Grid
      areas={{
        base: ['header', 'content'],
        M: ['header header', 'sidebar content'],
      }}
      columns={{
        base: [minmax(0, 'auto')],
        M: ['size-3000', minmax(0, 'auto')],
        L: ['size-3000', minmax(0, 'auto')],
      }}
      rows={{
        base: ['size-1700', minmax(0, 'auto')],
        M: ['size-1000', minmax(0, 'auto')],
      }}
      height="100%"
    >
      <View gridArea="header" paddingX="size-200" paddingY={{ base: 'size-100', M: '0px' }}>
        <Suspense fallback={<div>Loading...</div>}>
          <MainTopNav />
        </Suspense>
      </View>
      <View
        gridArea="sidebar"
        isHidden={{ base: true, M: false }}
        height="100%"
        overflow="auto"
      >
        <Suspense fallback={<div>Loading...</div>}>
          <FiltersSidePanel />
        </Suspense>
      </View>
      <View
        gridArea={{
          base: 'content',
          M: `${contentStart} / ${defaultContentColumnRow}`,
          L: `${contentStart} / ${contentEnd}`,
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <SearchResultsPanel />
        </Suspense>
      </View>
      <Suspense>
        <AssetPreviewDialogContainer />
      </Suspense>
      <ToastContainer />
    </Grid>
  );
}

export default App;
