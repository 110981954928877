/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable no-param-reassign */
/* eslint-disable padded-blocks */

import { v4 as uuid } from 'uuid';

async function generateCodeChallenge(codeVerifier) {
  const digest = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(codeVerifier),
  );

  return window.btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

function parseJwt(token) {
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(
        // eslint-disable-next-line prefer-template
        (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
      )
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

function jwtExpiresIn(jwt) {
  if (!jwt) {
    return null;
  }
  return jwt.exp * 1000 - Date.now();
}

/**
 * Handles the authentication flow with Frontegg. Small but generic implementation
 * that loads any larger Frontegg libraries on demand to not impact LHS.
 */
export default class Authenticator {
  /**
   * Creates an Authenticator instance. This would typically be a singleton on the page.
   *
   * This constructor only creates the object. To initialize it, call the `init()` method.
   *
   * @param {*} opts Options
   * @param {string} opts.domain Frontegg login domain (required)
   * @param {string} opts.clientId Frontegg client id (required)
   * @param {string} opts.appUri URL of the app to redirect to after a successfull login (required)
   * @param {string} opts.tenantId Frontegg tenant ID of the current page (optional, needed for `showAdminPortal()`)
   * @param {boolean} opts.autoLogin Whether to automatically redirect to the login page on `init()` (default false)
   * @param {number} opts.tokenCheckInterval How often to check for an expired token in msec (default 10 seconds)
   * @param {number} opts.tokenExpiryMargin Refresh token when it expires in less than this in msec (default 1 minute)
   * @param {boolean} opts.debugLog Whether to log debug messages (default false)
   */
  constructor(opts) {
    this.loginDomain = opts.domain;
    this.clientId = opts.clientId;
    this.appUri = opts.appUri;
    this.tenantId = opts.tenantId;

    this.autoLogin = opts.autoLogin;
    this.tokenExpiryMargin = opts.tokenExpiryMargin || 60 * 1000; // 1 minute
    this.tokenCheckInterval = opts.tokenCheckInterval || this.tokenExpiryMargin / 2;
    // this.tokenCheckInterval = opts.tokenCheckInterval || 10 * 1000; // 10 seconds
    this.debugLog = opts.debugLog || false;

    this.listeners = [];
  }

  // API methods ----------------------------------------------------------

  /**
   * Register a callback for an event. Available events:
   * - 'authenticated' - called when the user is authenticated, argument will be the parsed
   *    JWT access token
   * - 'not_authenticated' - called when the user is not authenticated (autoLogin=false and
   *    no logged in state)
   * @param event name of the event
   * @param callback function to call when the event is triggered
   */
  on(event, callback) {
    if (typeof callback === 'function' && typeof event === 'string') {
      this.listeners.push({ event, callback });
    }
  }

  /**
   * Returns if the user is currently logged in.
   * @returns true if the user is authenticated, false otherwise
   */
  isAuthenticated() {
    return this.accessToken !== undefined;
  }

  /**
   * Returns the JWT access token of the logged in user or null if the user is not authenticated.
   * This raw token can be used in API calls to the backend as the Authorization header with
   * Bearer scheme. This method is async as it might do a request to refresh the token if it is
   * expired.
   *
   * @param redirectToLogin if true, the user will be redirected to the login page if no longer
   *                        authenticated (ie. refreshing the token also failed)
   * @returns the JWT access token or null if the user is not authenticated
   */
  async getAccessToken({ redirectToLogin = true } = {}) {
    if (!this.accessToken) {
      return null;
    }

    if (this._isTokenValid(this.userToken)) {
      return this.accessToken;
    } else if (await this._silentTokenRefresh({ redirectToLogin })) {
      return this.accessToken;
    } else {
      return null;
    }
  }

  /**
   * Returns the user aka parsed JWT access token of the logged in user or null if the user
   * is not authenticated. This includes basic information about the user.
   * This method is async as it might do a request to refresh the token if it is expired.
   *
   * @param redirectToLogin if true, the user will be redirected to the login page if no
   *                        longer authenticated (ie. refreshing the token also failed)
   * @returns the parsed JWT with basic user information or null if the user is not authenticated
   */
  async getUser({ redirectToLogin = true } = {}) {
    if (!this.userToken) {
      return null;
    }

    if (this._isTokenValid(this.userToken)) {
      return this.userToken;
    } else if (await this._silentTokenRefresh({ redirectToLogin })) {
      return this.userToken;
    } else {
      return null;
    }
  }

  /**
   * Retrieve the detailed user profile.
   * @returns user profile object or null if the user is not logged in or the request failed
   */
  async getUserProfile() {
    return this._jsonFetch(
      `https://${this.loginDomain}/identity/resources/users/v2/me`,
    );
  }

  /**
   * Retrieve the tenants of the current user.
   * @returns array of tenant objects or null if the user is not logged in or the request failed
   */
  async getTenants() {
    return this._jsonFetch(
      `https://${this.loginDomain}/identity/resources/users/v2/me/tenants`,
    );
  }

  /**
   * Checks if the user has the specified permission
   * @param {string} permission the permission key to check
   */
  hasPermission(permission) {
    const permissions = this.userToken?.permissions || [];
    return permissions.includes(permission);
  }

  /**
   * Initiates the Authenticator. Call this at the earliest opportunity. This might redirect
   * the current page to a login page.
   */
  async init() {
    this._debug('Authenticator: init()');

    // run frequent auth token refresh check
    window.setInterval(() => {
      // this._debug('Authenticator: checking for expired token');
      if (!this.userToken || !this._isTokenValid(this.userToken)) {
        this._debug('Authenticator: token needs a refresh. expires in', jwtExpiresIn(this.userToken) / 1000, 'seconds');
        this._silentTokenRefresh();
      }
    }, this.tokenCheckInterval);

    // detect if we got the oauth callback (must happen first)
    if (window.location.search.includes('code') && window.location.search.includes('state')) {
      this._debug('Authenticator: code and state detected');
      await this._handleOauthCallback(window.location.href);
      return;
    }

    // see if we are already logged in
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      const userToken = parseJwt(accessToken);

      if (this._isTokenValid(userToken)) {
        this._debug('Authenticator: valid access_token detected in localStorage');
        this._setToken(accessToken, userToken);
      } else {
        this._debug('Authenticator: expired access_token detected in localStorage, starting silent token refresh');
        await this._silentTokenRefresh();
      }

      return;
    }

    if (this.autoLogin) {
      this._debug('Authenticator: autoLogin enabled, starting login()');
      this.login();
    } else {
      this._debug('Authenticator: autoLogin disabled, starting not_authenticated');
      this._emit('not_authenticated');
    }
  }

  /**
   * Redirects the user to the hosted login page.
   */
  async login() {
    this._debug(`Authenticator: login(), redirect_uri = ${this.appUri}`);

    // if not authenticated and need login, store the params into session storage
    if (window.location.search) {
      sessionStorage.setItem('redirectParams', window.location.search);
    }

    this._clearTokens();

    const state = uuid();
    sessionStorage.setItem('oauth_state', state);
    const codeVerifier = uuid();
    sessionStorage.setItem('oauth_code_verifier', codeVerifier);

    const url = new URL(`https://${this.loginDomain}/oauth/authorize`);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('scope', 'openid email profile');
    url.searchParams.append('client_id', this.clientId);
    url.searchParams.append('redirect_uri', this.appUri);
    url.searchParams.append('state', state);
    url.searchParams.append(
      'code_challenge',
      await generateCodeChallenge(codeVerifier),
    );

    window.location.href = url.href;
  }

  /**
   * Logs the user out. This will redirect the user to the specified redirect URL.
   *
   * @param redirect redirect URL to redirect the user to after logout.
   *    defaults to the current page.
   */
  logout(redirect = window.location.href) {
    this._debug(`Authenticator: logout(), post_logout_redirect_uri = ${redirect}`);

    this._clearTokens();

    window.location.href = `https://${this.loginDomain}/oauth/logout?post_logout_redirect_uri=${encodeURIComponent(redirect)}`;
  }

  /**
   * Show the user administration settings screen.
   *
   * @param displayOptions display options for the Frontegg admin portal. This is the `adminPortal` object documented on
   *                       https://docs.frontegg.com/docs/customizing-admin-portal-modules
   *                       Code reference: see `AdminPortalThemeOptions` in the `@frontegg/types` library.
   *                       Find it on https://www.npmjs.com/package/@frontegg/types?activeTab=code
   *                       inside `ThemeOptions/AdminPortalThemeOptions.d.ts`.
   */
  async showAdminPortal(displayOptions = {}) {
    this._showLoadingSpinner();

    let accessToken = await this.getAccessToken();
    if (accessToken) {
      const fronteggApp = await this._getFronteggApp(displayOptions);

      let user = await this.getUser();
      if (this.tenantId !== user.tenantId) {
        this._debug(`Authenticator: changing users current tenant id ${user.tenantId} to ${this.tenantId}`);

        await this._setCurrentTenant(this.tenantId);
        accessToken = await this.getAccessToken();
        user = await this.getUser();
      }

      await this._updateAdminPortalAuth();

      fronteggApp.showAdminPortal();
      this._hideLoadingSpinner();
    }
  }

  /**
   * Wrapper around standard fetch() that adds the authentication header with the
   * current user's credentials/access token.
   *
   * @param {String} url the URL to fetch
   * @param {Object} opts An object containing any custom settings that you want to apply to the request
   * @returns A Promise that resolves to a Response object.
   */
  async fetch(url, opts = {}) {
    opts.headers = opts.headers || {};
    opts.headers.Authorization = `Bearer ${await this.getAccessToken()}`;
    return fetch(url, opts);
  }

  // internal methods -----------------------------------------------------------------------------------

  _emit(event, ...data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const listener of this.listeners.filter((l) => l.event === event)) {
      setTimeout(listener.callback.bind(this, ...data), 0);
    }
  }

  _isTokenValid(userToken) {
    // see if access token expires soon
    return jwtExpiresIn(userToken) >= this.tokenExpiryMargin;
  }

  async _setToken(accessToken, parsedToken) {
    const expiresIn = jwtExpiresIn(parsedToken);
    this._debug('Authenticator: token expires in', expiresIn / 1000, 'seconds. refresh after', (expiresIn - this.tokenExpiryMargin) / 1000, 'seconds');

    this.accessToken = accessToken;
    this.userToken = parsedToken;
    this._emit('authenticated', this.userToken, this.accessToken);

    await this._updateAdminPortalAuth();
  }

  _clearTokens() {
    localStorage.removeItem('access_token');
    delete this.accessToken;
    delete this.userToken;
  }

  _handleTokenResponse(result) {
    const rawToken = result.access_token;
    const parsedToken = parseJwt(rawToken);

    this._debug('Authenticator: got a new access_token.');

    // Note: do NOT store refresh_token in local or session storage
    localStorage.setItem('access_token', rawToken);

    this._setToken(rawToken, parsedToken);
  }

  async _silentTokenRefresh({ redirectToLogin = true } = {}) {
    if (this.silentTokenRefreshPromise) {
      this._debug('Authenticator: silent token refresh already in progress');
      return this.silentTokenRefreshPromise;
    } else {
      this.silentTokenRefreshPromise = this._doSilentTokenRefresh({ redirectToLogin });
      const result = await this.silentTokenRefreshPromise;
      delete this.silentTokenRefreshPromise;
      return result;
    }
  }

  async _doSilentTokenRefresh({ redirectToLogin = true } = {}) {
    this._debug('Authenticator: doSilentTokenRefresh');
    try {
      const response = await fetch(
        `https://${this.loginDomain}/oauth/authorize/silent`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        },
      );

      if (response.ok) {
        this._handleTokenResponse(await response.json());
        return true;
      } else if (redirectToLogin) {
        this._debug('Authenticator: silent refresh failed with', response.status, ', redirectToLogin=true, redirecting to login page');
        this.login();
        // Note that login() will redirect the page
        return false;
      } else {
        this._debug('Authenticator: silent refresh failed with', response.status, ', redirectToLogin=false, returning false');
        return false;
      }
    } catch (e) {
      this._debug('Authenticator: silent refresh failed, returning false. Error:', e.message);
      return false;
    }
  }

  async _handleOauthCallback(callbackHref) {
    const callbackUrl = new URL(callbackHref);
    const state = callbackUrl.searchParams.get('state');
    const expectedState = sessionStorage.getItem('oauth_state');
    sessionStorage.removeItem('oauth_state');

    if (state !== expectedState) {
      console.error('Login failed - state mismatch');
      window.location.href = this.appUri;
      return;
    }

    const codeVerifier = sessionStorage.getItem('oauth_code_verifier');
    if (!codeVerifier) {
      console.error('Login failed - missing code_verifier');
      window.location.href = this.appUri;
      return;
    }

    const response = await fetch(`https://${this.loginDomain}/oauth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'authorization_code',
        code: callbackUrl.searchParams.get('code'),
        redirect_uri: this.appUri,
        code_verifier: codeVerifier,
      }),
    });

    sessionStorage.removeItem('oauth_code_verifier');

    if (response.ok) {
      this._debug('Authenticator: /oauth/token successful');

      this._debug(`Authenticator: setting history to ${this.appUri} to get rid of code and state in url`);
      window.history.replaceState({}, '', this.appUri);

      this._handleTokenResponse(await response.json());

      if (sessionStorage.getItem('redirectParams')) {
        window.location.href = this.appUri + sessionStorage.getItem('redirectParams');
        sessionStorage.removeItem('redirectParams');
      }

    } else {
      this._debug(`Authenticator: /oauth/token failed with ${response.status}`);

      // prevent endless redirect loops if this.autoLogin is true
      if (!this.autoLogin) {
        // token exchange failed, go back to original page
        this._debug(`Authenticator: redirecting to ${this.appUri}`);
        window.location.href = this.appUri;
      }
    }
  }

  async _setCurrentTenant(tenantId) {
    const accessToken = await this.getAccessToken();

    const response = await fetch(`https://${this.loginDomain}/identity/resources/users/v1/tenant`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'frontegg-user-id': this.userToken.sub,
        'frontegg-tenant-id': tenantId,
      },
      body: JSON.stringify({ tenantId }),
    });

    if (response.ok) {
      await this._silentTokenRefresh();
    } else {
      this._debug('Authenticator: failed to switch current tenant to ', tenantId, ', response status:', response.status);
    }
  }

  async _jsonFetch(url, opts) {
    const response = await this.fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      ...opts,
    });

    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  }

  /**
   * Loads the Frontegg JavaScript SDK and initializes the Frontegg application
   * @param   {Object} adminPortalDisplayOptions Options to customize the display of the admin portal
   * @returns {Promise<FronteggApp>} A promise that resolves with the Frontegg application
   *          object after the Frontegg JavaScript SDK is loaded and initialized.
   * @throws {Error} An error is thrown if the Frontegg JavaScript SDK fails to load.
   */
  async _getFronteggApp(adminPortalDisplayOptions) {
    // eslint-disable-next-line no-return-assign
    return this.fronteggApp = this.fronteggApp || await new Promise((resolve, reject) => {
      const script = document.createElement('script');
      // development js: https://unpkg.com/@frontegg/js@6.74.0/umd/frontegg.development.js
      script.src = 'https://unpkg.com/@frontegg/js@6.74.0/umd/frontegg.production.min.js';
      script.onload = () => {
        const fronteggApp = window.Frontegg.initialize({
          hostedLoginBox: true,
          authOptions: {
            keepSessionAlive: true,
          },
          contextOptions: {
            baseUrl: `https://${this.loginDomain}`,
            clientId: this.clientId,
          },
          themeOptions: {
            adminPortal: adminPortalDisplayOptions,
          },
        });
        resolve(fronteggApp);
      };
      script.onerror = reject;
      document.body.append(script);
    });
  }

  async _updateAdminPortalAuth() {
    if (window.Frontegg) {
      const user = this.userToken;

      const expires = user.exp * 1000;
      const expiresIn = expires - Date.now();

      window.Frontegg.HostedLogin.setAuthentication(
        true,
        this.accessToken,
        {
          ...await this.getUserProfile(),
          expires,
          expiresIn,
          accessToken: this.accessToken,
        },
      );
    }
  }

  _showLoadingSpinner() {
    if (this.spinner) {
      this.spinner.style.display = 'block';
    } else {
      const style = document.createElement('style');
      style.textContent = `
        .spinner {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.7);
          z-index: 9999;
        }

        .spinner-animation {
          width: 60px;
          height: 60px;
          border: 5px solid #fff;
          border-top-color: #000;
          border-radius: 50%;
          animation: spin 1s infinite linear;
          margin: 15px auto;
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }`;
      document.head.appendChild(style);

      this.spinner = document.createElement('div');
      this.spinner.classList.add('spinner');

      const spinnerAnimation = document.createElement('div');
      spinnerAnimation.classList.add('spinner-animation');
      this.spinner.appendChild(spinnerAnimation);

      document.body.appendChild(this.spinner);
    }
  }

  _hideLoadingSpinner() {
    if (this.spinner) {
      this.spinner.style.display = 'none';
    }
  }

  _debug(...args) {
    if (this.debugLog) {
      console.debug(...args);
    }
  }
}
