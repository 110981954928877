/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Checkbox, CheckboxGroup } from '@adobe/react-spectrum';
import { STATUSES } from 'components/utils';
import { ACTIONS, useHitDispatch } from 'providers/HitProvider';
import CLStatusIcon from 'components/cl-icon/CLStatusIcon';

export default function StatusFilter({ options, values }) {
  const hitDispatch = useHitDispatch();
  const computedValues = values?.map((value) => value.replace('assetStatus:', ''));
  const handleCheckboxChange = (selection) => {
    hitDispatch(ACTIONS.setFilters('assetStatus', selection));
  };

  return (
    <CheckboxGroup
      onChange={handleCheckboxChange}
      isEmphasized={true}
      key="assetStatus"
      value={computedValues}
      data-instance-id="search-filter-status-group"
      aria-label="Filter assets by status"
    >
      {STATUSES.map((status) => (
        <Checkbox value={status} key={status}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <CLStatusIcon status={status} />
            {options[status] ? `${status} (${options[status]})` : status}
          </div>
        </Checkbox>
      ))}
    </CheckboxGroup>
  );
}
