/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Checkbox, CheckboxGroup } from '@adobe/react-spectrum';
import { LAYERED_MIMETYPE_MAP, getFileTypeName } from 'components/utils';
import { ACTIONS, useHitDispatch } from 'providers/HitProvider';

export default function MimeTypeFilter({ options, values, fullFacets }) {
  const hitDispatch = useHitDispatch();

  const compressedOptions = {};
  const layeredOptions = {};
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const mimeType in fullFacets.sourceMimeTypes) {
    if (LAYERED_MIMETYPE_MAP[mimeType]) {
      layeredOptions[mimeType] = options[mimeType] !== undefined ? options[mimeType] : 0;
    } else {
      compressedOptions[mimeType] = options[mimeType] !== undefined ? options[mimeType] : 0;
    }
  }

  const selectedValues = values?.map((value) => value.replace('sourceMimeTypes:', ''));

  const handleCheckboxChange = (selection) => {
    hitDispatch(ACTIONS.setFilters('sourceMimeTypes', selection));
  };

  return (
    <CheckboxGroup
      onChange={handleCheckboxChange}
      isEmphasized={true}
      key="sourceMimeTypes"
      value={selectedValues}
      data-instance-id="search-filter-group"
      aria-label="Filter assets by mime type"
    >
      {Object.keys(compressedOptions).length > 0 && (
        <>
          <span style={{ fontSize: '13px' }}>Image (compressed)</span>
          {Object.entries(compressedOptions).map(([name, count]) => (
            <Checkbox value={name} key={name}>
              {count === 0 ? getFileTypeName(name) : `${getFileTypeName(name)} (${count})`}
            </Checkbox>
          ))}
        </>
      )}
      {Object.keys(layeredOptions).length > 0 && (
        <>
          <span style={{ fontSize: '13px' }}>Image (layered)</span>
          {Object.entries(layeredOptions).map(([name, count]) => (
            <Checkbox value={name} key={name}>
              {count === 0 ? getFileTypeName(name) : `${getFileTypeName(name)} (${count})`}
            </Checkbox>
          ))}
        </>
      )}
    </CheckboxGroup>
  );
}
