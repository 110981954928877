/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Checkbox, CheckboxGroup } from '@adobe/react-spectrum';
import { ACTIONS, useHitDispatch } from 'providers/HitProvider';

const capitalizeLabel = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export default function ContentCredentialFilter({ options, values }) {
  const hitDispatch = useHitDispatch();

  const selectedValues = values?.map((value) => value.replace('c2paLevel:', ''));

  const handleCheckboxChange = (selection) => {
    hitDispatch(ACTIONS.setFilters('c2paLevel', selection));
  };

  return (
    <CheckboxGroup
      onChange={handleCheckboxChange}
      isEmphasized={true}
      key="c2paLevel"
      value={selectedValues}
      data-instance-id="search-filter-group"
      aria-label="Filter assets by content credentials"
    >
      {Object.entries(options).map(([name, count]) => (
        <Checkbox value={name} key={name}>
          {count === 0 ? capitalizeLabel(name) : `${capitalizeLabel(name)} (${count})`}
        </Checkbox>
      ))}
    </CheckboxGroup>
  );
}
