/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Flex, Checkbox } from '@adobe/react-spectrum';
import { useHit, useHitDispatch, ACTIONS } from 'providers/HitProvider.js';
import { getSourceDisplayName } from 'components/utils';
import { useRepositories } from 'providers/RepositoriesProvider';

const getCount = (object, key) => {
  if (!object || !object[key]) {
    return 0;
  }
  return object[key];
};

const RepositoryFilter = ({ fullFacets }) => {
  const { facets, filters } = useHit();
  const hitDispatch = useHitDispatch();
  const { repositories } = useRepositories();
  const { sourceIds, sourceTypes } = facets;
  const fullSourceIds = fullFacets.sourceIds;
  const fullSourceTypes = fullFacets.sourceTypes;

  if (!fullSourceIds || !fullSourceTypes) {
    return null;
  }

  // name, count, description, sourceIds, sourceTypes
  const sourceRepositories = repositories
    .filter((repo) => fullSourceIds[repo.sourceId])
    .map((repo) => ({
      ...repo,
      count: getCount(sourceIds, repo.sourceId),
      id: repo.sourceId,
      facetType: 'sourceIds',
      key: `sourceIds:${repo.sourceId}`,
    }));

  const sources = Object.entries(fullSourceTypes)?.map(([id]) => {
    const displayName = getSourceDisplayName(id);
    const childs = sourceRepositories.filter((repo) => repo.sourceType === id);
    return {
      id,
      name: displayName,
      count: getCount(sourceTypes, id),
      childs,
      facetType: 'sourceTypes',
      key: `sourceTypes:${id}`,
    };
  });

  const flatSources = sources.reduce((acc, source) => {
    acc.push({ ...source, level: 0 });
    const flatChild = source.childs.map((child) => ({ ...child, level: 1 }));
    acc.push(...flatChild);
    return acc;
  }, []);

  const onSelection = (facetType, facetId, isSelected) => {
    hitDispatch(ACTIONS.updateFilter(facetType, facetId, isSelected));
  };

  return (
    <Flex direction="column">
      {flatSources.map((source) => (
        <Checkbox
          key={source.id}
          value={source.id}
          isEmphasized={true}
          isSelected={filters[source.facetType].includes(source.key)}
          marginStart={source.level === 1 ? 'size-100' : 'size-0'}
          onChange={(selected) => onSelection(source.facetType, source.id, selected)}
          data-instance-id="search-filter-group"
        >
          {source.count === 0 ? source.name : `${source.name} (${source.count})`}
        </Checkbox>
      ))}
    </Flex>
  );
};

export default RepositoryFilter;
