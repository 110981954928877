/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { createContext, useContext, useEffect, useReducer, useCallback, useState } from 'react';
import { getSources } from 'connectors/SourceConnector';

const defaultState = {
  repositories: [],
  cursor: null,
  selectedRepository: null,
};

const RepositoriesContext = createContext(defaultState);

const RepositoriesDispatchContext = createContext(null);

export function useRepositories() {
  return useContext(RepositoriesContext);
}

export function useRepositoriesDispatch() {
  return useContext(RepositoriesDispatchContext);
}

const ACTION_NAMES = {
  SET_REPOSITORIES: 'SET_REPOSITORIES',
  SET_SELECTED_REPOSITORY: 'SET_SELECTED_REPOSITORY',
  CLEAR_SELECTED_REPOSITORY: 'CLEAR_SELECTED_REPOSITORY',
  CREATE_REPOSITORY: 'CREATE_REPOSITORY',
};

export const ACTIONS = {
  setRepositories: (repositories, cursor) => ({
    type: ACTION_NAMES.SET_REPOSITORIES,
    repositories,
    cursor,
  }),
  setSelectedRepository: (repository) => ({
    type: ACTION_NAMES.SET_SELECTED_REPOSITORY,
    repository,
  }),
  clearSelectedRepository: () => ({
    type: ACTION_NAMES.CLEAR_SELECTED_REPOSITORY,
  }),
  createRepository: () => ({
    type: ACTION_NAMES.CREATE_REPOSITORY,
  }),
};

const REDUCERS = {
  [ACTION_NAMES.SET_REPOSITORIES]: (state, action) => ({
    ...state,
    repositories: action.repositories,
    cursor: action.cursor,
  }),
  [ACTION_NAMES.SET_SELECTED_REPOSITORY]: (state, action) => ({
    ...state,
    selectedRepository: action.repository,
  }),
  [ACTION_NAMES.CLEAR_SELECTED_REPOSITORY]: (state) => ({
    ...state,
    selectedRepository: null,
  }),
  [ACTION_NAMES.CREATE_REPOSITORY]: (state) => ({
    ...state,
    selectedRepository: {
      sourceId: 'new',
    },
  }),
};

const repositoriesReducer = (state, action) => {
  const reducer = REDUCERS[action.type];
  if (reducer) {
    return reducer(state, action);
  }
  return state;
};

const useRepositoryFetch = (state, dispatch) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateRepositories = useCallback(async () => {
    setIsLoading(true);
    const { items: sources, cursor } = await getSources(state.cursor);
    dispatch(ACTIONS.setRepositories(sources, cursor));
    setIsLoading(false);
  }, [dispatch, state]);

  // fetch initial sources
  useEffect(() => {
    updateRepositories();
  }, [dispatch]);

  return { updateRepositories, isLoading };
};

export function RepositoryProvider({ children }) {
  const [state, dispatch] = useReducer(repositoriesReducer, defaultState);
  const repositoryFetch = useRepositoryFetch(state, dispatch);

  return (
    <RepositoriesContext.Provider value={{ ...state, ...repositoryFetch }}>
      <RepositoriesDispatchContext.Provider value={dispatch}>
        {children}
      </RepositoriesDispatchContext.Provider>
    </RepositoriesContext.Provider>
  );
}
