/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

const displayNameMap = {
  website: 'Website',
  microsoft: 'Sharepoint',
  stock: 'Adobe Stock',
  brandportal: 'Brand Portal',
  dropbox: 'Dropbox',
  aem: 'Adobe Experience Manager',
  rum: 'Website',
};

export const getSourceDisplayName = (source) => displayNameMap[source] || source;

const filterLabelDisplayNameMap = {
  sourceTypes: 'Repository',
  sourceIds: 'Repository ID',
};

export const getFilterDisplayName = (source) => filterLabelDisplayNameMap[source] || source;

// for localhost & hlx.page environments
const DEFAULT_TEST_ACCOUNT = {
  spaceHost: 'test.findmy.media',
  spaceId: '669fbb6f-f6e2-49ae-89eb-fcc20a6aa878',
  spaceName: 'Adobe Test',
};

export const isDev = () => (
  window.location.host === 'localhost:3000'
    || window.location.host.endsWith('--content-lake-ui--adobe.hlx.page')
);

export const getSpaceId = () => {
  if (isDev()) {
    return localStorage.getItem('spaceId') || DEFAULT_TEST_ACCOUNT.spaceId;
  }
  return document.querySelector('meta[name="x-space-id"]')?.content;
};

export const getSpaceHost = () => {
  if (isDev()) {
    return localStorage.getItem('spaceHost') || DEFAULT_TEST_ACCOUNT.spaceHost;
  }
  return window.location.host;
};

export const getSpaceName = () => {
  if (isDev()) {
    return localStorage.getItem('spaceName') || DEFAULT_TEST_ACCOUNT.spaceName;
  }
  return document.querySelector('meta[name="x-space-name"]')?.content;
};

export const getAssetUrl = (asset, width, height) => {
  if (!asset.file) {
    return '';
  }

  const url = new URL(`https://${getSpaceHost()}/media/${asset.file}`);

  if (width) url.searchParams.append('width', width);
  if (height) url.searchParams.append('height', height);

  return url;
};

export const getDownloadUrl = (asset, format = 'png') => {
  if (!asset.file) {
    return '';
  }

  return new URL(`https://${getSpaceHost()}/media/${asset.file}?format=${format}`);
};

export const isSafari = () => navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

export const replaceExtension = (filename, newExtension) => {
  const sepIdx = filename.lastIndexOf('.');
  if (sepIdx >= 0) {
    // eslint-disable-next-line no-param-reassign
    filename = filename.substr(0, sepIdx);
  }
  return `${filename}.${newExtension}`;
};

export const getExtension = (filename) => {
  const sepIdx = filename.lastIndexOf('.') + 1;
  return filename.substr(sepIdx);
};

export const isTestEnvironment = () => (
  getSpaceHost() === DEFAULT_TEST_ACCOUNT.spaceHost
);

export const LAYERED_MIMETYPE_MAP = {
  'application/vnd.adobe.illustrator': 'AI',
  'image/vnd.adobe.photoshop': 'PSD',
  'application/pdf': 'PDF',
  'image/svg+xml': 'SVG',
};

export const getFileTypeName = (mimeType) => {
  if (!mimeType) {
    return '';
  }
  if (LAYERED_MIMETYPE_MAP[mimeType]) {
    return LAYERED_MIMETYPE_MAP[mimeType];
  } else if (mimeType.startsWith('image/')) {
    return mimeType.replace('image/', '').toUpperCase();
  } else {
    return mimeType;
  }
};

export const getFileTypeColor = (mimeType) => {
  const FileTypeColor = {
    'application/vnd.adobe.illustrator': '#330203',
    'image/vnd.adobe.photoshop': '#011E36',
    'application/pdf': '#000B1D',
  };
  if (FileTypeColor[mimeType]) {
    return FileTypeColor[mimeType];
  } else {
    return 'var(--spectrum-alias-background-color-label-gray)';
  }
};

// order defines status facet
export const STATUSES = [
  'Ready to Use',
  'Unknown',
  'Work in Progress',
  'In Review',
  'Embargoed',
  'Published',
  'Archived',
];

// order defines selecting merged status on asset detail
// needed as long as we have no single cluster entry in the index
export const STATUS_ORDER = [
  'Unknown',
  'Work in Progress',
  'In Review',
  'Embargoed',
  'Ready to Use',
  'Published',
  'Archived',
];

export const truncateMiddle = (str, maxLength, separator = '…') => {
  if (str.length <= maxLength) {
    return str;
  }

  const length = maxLength - separator.length;

  return str.substr(0, Math.ceil(length / 2))
         + separator
         + str.substr(str.length - Math.floor(length / 2));
};

export const mapUrlsByDomain = (urls) => {
  const domains = {};
  for (const u of urls) {
    const url = new URL(u);
    const domain = domains[url.host] || (domains[url.host] = {
      urls: [],
    });
    domain.urls.push(url);
  }
  return domains;
};
